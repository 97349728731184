<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useSiteStore } from '~/stores/site.store';
import { useBonusService } from '~/composables/services/useBonusService';
import type { CasinoBonus } from '~/interfaces/dto/bonus/response';

const bonusService = useBonusService();
const site = useSiteStore();
const activeBonus = ref(0);
const pendingBonus = ref(0);
const loading = ref(true);

async function getBonusStatus() {
  try {
    await bonusService.getBonusStack().then((data) => {
      loading.value = false;
      if (data) {
        activeBonus.value = data.activeCasinoBonus?.totalAwardedBonusAmount;
        pendingBonus.value = totalPendingBonus(data.pendingCasinoBonuses);
      }
    });
  } catch (e) {
    loading.value = false;
    console.error(e);
  }
}

function totalPendingBonus(pendingBonuses: CasinoBonus[]) {
  if (pendingBonuses.length > 0) {
    let sum = 0;
    for (let i = 0; i < pendingBonuses.length; i++) {
      sum += pendingBonuses[i]?.totalAwardedBonusAmount;
    }
    return sum;
  } else {
    return 0;
  }
}

onMounted(async () => {
  await getBonusStatus();
});
</script>

<template>
  <LazyUserInterfaceGenericLoader v-if="loading" />
  <div class="p-2 text-base-priority" v-else>
    <div class="mb-2.5 mt-0.5">
      {{ $t('withdrawal-alert-summary') }}
    </div>
    <div
      class="flex justify-between items-center dark:bg-dark-800 bg-light-50 rounded-lg px-2 mb-2.5"
      v-if="activeBonus > 0"
    >
      <p class="my-2.5 font-bold text-base-priority">
        <span class="text-gold-500 capitalize">{{ $t('active') }}</span>
        {{ $t('bonus') }}
      </p>
      <div
        class="text-right text-base-priority font-bold"
        v-html="useFormatCurrency(activeBonus)"
      />
    </div>
    <div
      class="flex justify-between items-center dark:bg-dark-800 bg-light-50 rounded-lg px-2"
      v-if="pendingBonus > 0"
    >
      <p class="my-2.5 font-bold text-base-priority">
        {{ $t('pending-bonus') }}
      </p>
      <div
        class="text-right text-base-priority font-bold"
        v-html="useFormatCurrency(pendingBonus)"
      />
    </div>
    <div
      class="flex justify-between items-center px-2 mt-4 mb-2"
      v-if="activeBonus > 0"
    >
      <p class="my-2.5 font-bold text-base-priority">
        {{ $t('total') }}
      </p>
      <div
        class="text-right text-base-priority font-bold"
        v-html="useFormatCurrency(activeBonus + pendingBonus)"
      />
    </div>
  </div>
  <div class="dark:bg-dark-800 bg-light-50 w-full p-2">
    <Button
      class="w-full justify-center"
      type="other"
      @click="site.deactivateModal()"
    >
      {{ $t('confirm') }}
    </Button>
  </div>
</template>

<style lang="scss" scoped>
p {
  line-height: 16px;
}
</style>
